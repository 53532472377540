import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {RunnerService} from '../../../services/runner.service';
import {GameService} from '../../../services/game.service';
import {CatcherService} from "../../../services/catcher.service";
import {RunningTeam} from "../../../models/running-team";

@Component({
  selector: 'app-my-teams',
  templateUrl: './my-teams.component.html',
  styles: [
  ]
})
export class MyTeamsComponent implements OnInit {

  constructor(public auth: AuthService, public runnerService: RunnerService, public game: GameService, public catcherService: CatcherService) { }

  ngOnInit(): void {
  }

  getTotalCost(runningTeams: RunningTeam[]) {
    return runningTeams.map((team) => team.members.length).reduce((a, b) => a + b, 0) * this.game.currentGame.costPerPerson;
  }

}
