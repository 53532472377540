<div *ngIf="auth.user && gameService.currentGame else loading">
  <app-header></app-header>

  <router-outlet></router-outlet>

  <app-toasts></app-toasts>

  <footer class="footer">
    <div class="container">
      <div class="row text-center">
        <div class="col-md">Eccleston Scout Group</div>
        <div class="col-md">widegame.app | Teams v{{version}}</div>
        <div class="col-md">Created by George Pears</div>
      </div>

    </div>
  </footer>


</div>
<ng-template #loading>Loading...</ng-template>
<div class="position-fixed top-0 end-0 p-3" style="z-index: 1000">
  <ngb-toast
    *ngFor="let toast of toastService.toasts" [class]="toast.bg ? 'text-white bg-' + toast.bg : ''"
    [header]="toast.header" [autohide]="true" [delay]="toast.delay || 5000" class="mb-2"
  >{{toast.body}}</ngb-toast>
</div>
