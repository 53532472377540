import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import {forkJoin, Observable, zip} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {map, mergeMap, switchMap, take, tap} from 'rxjs/operators';
import {GameService} from '../services/game.service';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard  {

  constructor(private auth: AuthService) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> {

    // Checks if user is logged in & if user is admin for current game
    return this.auth.currentUserIsManagerForGame();
  }
}
