import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {NotFoundComponent} from './components/errors/not-found/not-found.component';
import {MainComponent} from './components/layout/main/main.component';
import {AngularFireAuthGuard, canActivate, redirectLoggedInTo, redirectUnauthorizedTo} from '@angular/fire/compat/auth-guard';
import {SignInComponent} from './components/auth/sign-in/sign-in.component';
import {AdminGuard} from './guards/admin.guard';
import {AddRunnerComponent} from './components/pages/add-team/runner/add-runner.component';
import {MyTeamsComponent} from './components/pages/my-teams/my-teams.component';
import {AddCatcherComponent} from './components/pages/add-team/catcher/add-catcher.component';
import {RunningTeamComponent} from './components/pages/my-teams/running-team/running-team.component';
import {PendingChangesGuard} from './guards/pending-changes.guard';

const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);
const redirectLoggedInToItems = () => redirectLoggedInTo(['']);

const routes: Routes = [
  {
    path: '',
    component: MainComponent,
    canActivate: [AngularFireAuthGuard, AdminGuard], // Needs angular to redirect to sign-in if not logged in
    data: { authGuardPipe: redirectUnauthorizedToLogin },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: MyTeamsComponent, // TODO: Delete dashboard component copied from admin project
        canActivate: [AdminGuard], // Only needs to check for auth status, as already logged in to be here
      },
      {
        path: 'runner/:id',
        component: RunningTeamComponent,
        canActivate: [AdminGuard],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'add-team/runner',
        component: AddRunnerComponent, // Add runner component
        canActivate: [AdminGuard],
        canDeactivate: [PendingChangesGuard],
      },
      {
        path: 'add-team/catcher',
        component: AddCatcherComponent, // Add catcher component
        canActivate: [AdminGuard],
      },
    ],
  },
  { path: 'sign-in',  component: SignInComponent, ...canActivate(redirectLoggedInToItems)},
  { path: '**', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
