import {Component, HostListener, OnInit} from '@angular/core';
import {RunningTeam} from '../../../../models/running-team';
import {RunnerService} from '../../../../services/runner.service';
import {RunningTeamMember} from '../../../../models/running-team-member';
import {Router} from '@angular/router';
import {NgbDate} from '@ng-bootstrap/ng-bootstrap';
import {Observable} from 'rxjs';
import {AuthService} from '../../../../services/auth.service';

@Component({
  selector: 'app-runner',
  templateUrl: './add-runner.component.html',
  styles: []
})
export class AddRunnerComponent implements OnInit {

  constructor(public runnerService: RunnerService, private router: Router, public authService: AuthService) {
    this.runner.members = new Array<RunningTeamMember>();
  }

  step = 1;
  steps = 4;
  submitted = false;

  runner = {} as RunningTeam;

  protected readonly NgbDate = NgbDate;

  // @HostListener allows us to also guard against browser refresh, close, etc.
  @HostListener('window:beforeunload')
  canDeactivate(): Observable<boolean> | boolean {
    return this.step === 1 || this.submitted;
  }

  ngOnInit(): void {
  }

  progress(): number {
    return this.step / this.steps * 100;
  }

  nextStep(): void {
    this.step += 1;
  }

  previousStep(): void {
    this.step -= 1;
  }

  addMember(): void {
    const member = {} as RunningTeamMember;
    member.wantsFood = true;
    this.runner.members.push(member);
  }

  add(): void {
    this.runnerService.add(this.runner);
    this.submitted = true;
    this.router.navigate(['/']);
  }

  validate(): boolean {
    switch (this.step) {
      case 1: // Basic info
        if (this.runner.name?.length > 2 && this.runner.group?.length > 2) {
          return true;
        }
        break;
      case 2: // Category
        if (this.runner.category?.length > 2) {
          return true;
        }
        break;
      case 3: // POC
        return true;
      case 4: // Members
        return true;
    }
    return false;
  }
}
