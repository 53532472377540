import { Component, OnInit } from '@angular/core';
import {AuthService} from '../../../services/auth.service';
import {GameService} from '../../../services/game.service';
import {ToastService} from '../../../services/toast.service';
import packageJson from '../../../../../package.json';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styles: [
  ]
})
export class MainComponent implements OnInit {

  public version: string = packageJson.version;

  constructor(public auth: AuthService, public gameService: GameService, public toastService: ToastService) {
  }

  ngOnInit(): void {
  }

}
