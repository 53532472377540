<div class="container mt-5">
  <h1>Create your catching team</h1>
  <p class="mb-4 mt-4">Enter your new team's details below. <b>Only do this for yourself once, not for anyone else.</b></p>
  <p class="mb-4">Entering these details will grant you access to the catchers app on the day of the event, so <b>keep your password for this app safe</b>, as you'll use the same details then.</p>

  <div class="form-group mb-4">
    <label for="teamName">Team Name</label>
    <input [(ngModel)]="catcher.name" placeholder="E.g. The Catchers" type="text"
           class="form-control form-control-lg mt-1" id="teamName" name="teamName" autocomplete="off">
  </div>

  <div class="form-group mb-4">
    <label for="contactPhone">Phone Number</label>
    <input [(ngModel)]="catcher.contactNumber" placeholder="E.g. 07123 456 789" type="tel"
           class="form-control form-control-lg mt-1" name="contactPhone" id="contactPhone">
  </div>

  <div class="form-group mb-3">
    <label for="contactPhone">Other Team Members</label>
    <input [(ngModel)]="catcher.otherMembers" placeholder="E.g. John Smith & Jane Doe" type="text"
           class="form-control form-control-lg mt-1" name="contactPhone" id="otherMembers">
    <small>This doesn't include yourself - we got your name when you made your account.</small>
  </div>

  <p *ngIf="!validate() && catcher.name !== undefined && catcher.contactNumber != undefined" class="my-4 text-danger"><i class="fa-solid fa-triangle-exclamation me-2"></i>Please enter a
    valid team name & contact number!</p>
  <div class="text-end">
    <button [disabled]="!validate()" class="btn btn-primary btn-lg my-3" (click)="create()"><i
      class="fa-solid fa-check me-2"></i>Save
    </button>
  </div>



</div>
