import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../../services/auth.service';
import {GameService} from '../../../services/game.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {

  constructor(public auth: AuthService, public router: Router, public gameService: GameService) { }

  ngOnInit(): void {
  }

}
