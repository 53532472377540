import {Injectable} from '@angular/core';
import {GameService} from './game.service';
import {AngularFirestore, AngularFirestoreDocument} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {AuthService} from './auth.service';
import {Catcher} from '../models/catcher';
import firebase from 'firebase/compat/app';

@Injectable({
  providedIn: 'root'
})
export class CatcherService {

  // Never subscribe in service - either use async pipe in template, or subscribe directly in component code, saving result to local object
  // Normally will use 'first()' for forms, so it doesn't change as user is typing, then send that object
  //    back to service to be updated (don't just rely on service to hold current state)
  catcher$: Observable<Catcher>;

  constructor(private gameService: GameService, private afs: AngularFirestore, public auth: AuthService) {
      this.loadCatcherObservable();
  }

  loadCatcherObservable(): void {
    this.catcher$ = this.afs.doc<Catcher>(`games/${this.gameService.currentGame.id}/catchingTeams/${this.auth.user.email}`)
        .valueChanges();
  }

  create(catcher: Catcher): void {
    // Set catcher as current user
    catcher.id = this.auth.user.email;
    catcher.contactEmail = this.auth.user.email;
    catcher.contactName = this.auth.user.displayName;
    this.afs.doc(`games/${this.gameService.currentGame.id}/catchingTeams/${this.auth.user.email}`).set({...catcher}).then(() => {
      console.log(catcher);
    });
    // Set user as catcher
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${this.auth.user.email}`);
    const data = {
      'roles.catcher': firebase.firestore.FieldValue.arrayUnion(this.gameService.currentGame.id)
    };
    userRef.update(data).then(() => {
      this.loadCatcherObservable();
    });
  }


}
