
<div class="row m-0">
  <div class="col-md-8 m-0 p-0">
    <div class="text-center signin">
      <div class="form-signin">

        <!--  Preload loading gif -->
        <img style="display: none" src="/assets/img/loading.gif" alt="Loading...">

        <h1 class="h4 mb-5"><span class="widegame-logo signin-logo">widegame.app</span></h1>

        <div *ngIf="auth.loading" class="login-loader">
          <h2>Loading...</h2>
          <img src="/assets/img/loading.gif" alt="Loading...">
        </div>

        <form (ngSubmit)="signIn()" *ngIf="!auth.loading && mode == 'sign-in'">
          <h2>Teams Manager</h2>
          <p>Hi there! Please login to manage your teams for <strong>{{game.currentGame?.name}}.</strong></p>

          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" required
                   [(ngModel)]="email" name="Email">
            <label for="floatingInput">Email address</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword" placeholder="Password" required
                   [(ngModel)]="password" name="Password">
            <label for="floatingPassword">Password</label>
          </div>

          <div class="text-danger fw-900 mb-3" *ngIf="auth.errorMessage">{{auth.errorMessage}}</div>

          <button class="w-100 btn btn-lg btn-secondary text-white" type="submit">Sign in</button>

          <p class="mt-3">Forgotten your password? <a class="link fw-bold" (click)="mode = 'reset-password'">Reset it here!</a></p>

          <hr class="my-4"/>

          <p class="fw-bold mt-3">Not used this app yet? Create an account:</p>
          <button class="w-100 btn btn-lg btn-primary" type="button" (click)="mode = 'register'">Register</button>
        </form>

        <form (ngSubmit)="register()" *ngIf="!auth.loading && mode == 'register'">
          <h2>New Account</h2>
          <p>Enter your details below to create a new account and get started managing your <b>{{game.currentGame?.name}} teams.</b></p>
          <div class="form-floating mb-3">
            <input type="text" class="form-control" id="floatingInput0" placeholder="e.g. John Smith" required
                   [(ngModel)]="displayName" name="Name" [class.is-invalid]="displayName?.length < 3 || displayName == undefined">
            <label for="floatingInput0">Your Name</label>
          </div>
          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput2" placeholder="name@example.com" required
                   [(ngModel)]="email" name="Email" [class.is-invalid]="email?.length < 3 || email == undefined">
            <label for="floatingInput2">Email address</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword2" placeholder="Password" required
                   [(ngModel)]="password" name="Password" [class.is-invalid]="password != password2 || password?.length < 4 || password == undefined">
            <label for="floatingPassword2">Password</label>
          </div>
          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword3" placeholder="Password" required
                   [(ngModel)]="password2" name="Password2" [class.is-invalid]="password != password2 || password?.length < 4 || password2 == undefined">
            <label for="floatingPassword3">Password again</label>
            <div class="invalid-feedback text-start" *ngIf="!formValid()">
              Please fill in all fields!
            </div>
            <div class="invalid-feedback text-start" *ngIf="!passwordsMatch()">
              Make sure passwords match!
            </div>
          </div>

          <div class="text-danger fw-900 mb-3" *ngIf="auth.errorMessage">{{auth.errorMessage}}</div>

          <button class="w-100 btn btn-lg btn-primary" type="submit">Register</button>

          <p class="fw-900 mt-3">Already have an account? Login instead:</p>
          <button class="w-100 btn btn-lg btn-secondary text-white" type="button" (click)="mode = 'sign-in'">Sign in</button>

        </form>

        <form (ngSubmit)="resetPassword()" *ngIf="!auth.loading && mode == 'reset-password'">
          <h2>Teams Manager</h2>
          <p>Reset your password to manage your teams for <strong>{{game.currentGame?.name}}.</strong></p>

          <div class="form-floating mb-3">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com" required
                   [(ngModel)]="email" name="Email">
            <label for="floatingInput">Email address</label>
          </div>

          <div class="text-danger fw-900 mb-3" *ngIf="auth.errorMessage">{{auth.errorMessage}}</div>
          <p>This will send a password reset to the email above if you already have an account.</p>

          <button class="w-100 btn btn-lg btn-secondary text-white" type="submit">Send password reset</button>

          <p class="mt-3 link fw-bold"><a (click)="mode = 'sign-in'">Back to login</a></p>
        </form>

        <p class="mt-5 mb-3 text-muted">Eccleston Scout Group | <a class="text-primary" href="mailto:george@pears.me.uk">George Pears</a> {{year}}</p>


      </div>
    </div>

  </div>

  <div class="col-md-4 m-0 bg-secondary p-0 signin-banner">
  </div>
</div>

<div class="position-fixed top-0 start-0 p-3" style="z-index: 1000">

  <ngb-toast
    *ngFor="let toast of toast.toasts" [class]="toast.bg ? 'text-white bg-' + toast.bg : ''"
    [header]="toast.header" [autohide]="true" [delay]="toast.delay || 5000" class="mb-2"
  >{{toast.body}}</ngb-toast>
</div>
