import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {AngularFirestore} from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ScannerService {

  scanner$: Observable<any>;
  errorMessage: string;

  constructor(private afs: AngularFirestore) {
    this.setScannerId('dontError');
  }

  setScannerId(id: string): void {
    this.scanner$ = this.afs.doc(`scanners/${id}`).valueChanges().pipe(map(res => {
        if (!res && id !== 'dontError') {
          this.errorMessage = 'Scanner not found!';
        }
        else {
          this.errorMessage = '';
        }
        return res;
      }),
      catchError(msg => this.errorMessage = msg));
  }

  disconnect(): void {
    this.scanner$ = null;
  }
}
