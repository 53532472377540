<h3 class="my-5">I'd like to register a...</h3>

<div class="row">
  <div class="col-12 col-sm-6 col-lg-3 mb-3">
    <div routerLink="/add-team/runner" class="card-team-type shadow py-4 px-3 text-center">
      <i class="h1 mb-3 fa-solid fa-person-hiking"></i>
      <h5>Running Team</h5>
    </div>
  </div>
  <div class="col-12 col-sm-6 col-lg-3 mb-3">
    <div routerLink="/add-team/catcher" class="card-team-type shadow py-4 px-3 text-center">
      <i class="h1 mb-3 fa-solid fa-car-side"></i>
      <h5>Catching Team</h5>
    </div>
  </div>
</div>
