import {Penalty} from './penalty';
import {RunningTeamMember} from './running-team-member';
import {Catch} from './catch';

export interface RunningTeam {

  id: string;
  name: string;
  teamNumber: number;
  group: string;
  category: string;
  status: RunningTeamStatus;

  noMembers: number;

  disqualificationReason: string;
  checkInTime: Date;
  firstCatchTime: Date;
  noCatches: number;
  catches: Catch[];

  manager: string;

  contactName: string;
  contactPhone: string;
  contactEmail: string;
  contactAddress: string;

  penalties: Penalty[];
  members: RunningTeamMember[];
}

export enum RunningTeamStatus {
  unregistered = 'Unregistered', // Teams initially signed up as this
  registered = 'Registered', // After registration, before game starts
  running = 'Running', // After game starts all registered teams are set as running
  checkedIn = 'Checked-In', // After checkin
  disqualified = 'Disqualified', // Either retired or disqualified, disqualificationReason should be filled
  discarded = 'Discarded' // Didn't turn up to registration
}
