import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HeaderComponent} from './components/layout/header/header.component';
import {NotFoundComponent} from './components/errors/not-found/not-found.component';
import {MainComponent} from './components/layout/main/main.component';
import {environment} from '../environments/environment';
import {SignInComponent} from './components/auth/sign-in/sign-in.component';
import {FormsModule} from '@angular/forms';
import {AdminGuard} from './guards/admin.guard';
import {ToastsComponent} from './components/layout/toasts/toasts.component';
import {NgbDateAdapter, NgbDateParserFormatter, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CommonModule, DecimalPipe} from '@angular/common';
import {HighlightSearchPipe} from './pipes/highlight-search.pipe';
import {LoaderComponent} from './components/layout/loader/loader.component';
import {HttpClientModule} from '@angular/common/http';
import {SafePipe} from './pipes/safe.pipe';
import {TeamTypeComponent} from './components/pages/add-team/team-type/team-type.component';
import {AddRunnerComponent} from './components/pages/add-team/runner/add-runner.component';
import {AddCatcherComponent} from './components/pages/add-team/catcher/add-catcher.component';
import {MyTeamsComponent} from './components/pages/my-teams/my-teams.component';
import {RunningTeamComponent} from './components/pages/my-teams/running-team/running-team.component';
import {CustomDateParserFormatter, NgbDateFirestoreAdapter} from './services/ngb-date-firestore-adapter';
import {PendingChangesGuard} from './guards/pending-changes.guard';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AngularFireModule} from '@angular/fire/compat';
import {AngularFireAnalyticsModule} from '@angular/fire/compat/analytics';
import {AngularFirestoreModule} from '@angular/fire/compat/firestore';
import {AngularFireAuthModule} from '@angular/fire/compat/auth';
import {AngularFireAuthGuardModule} from '@angular/fire/compat/auth-guard';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    NotFoundComponent,
    MainComponent,
    SignInComponent,
    ToastsComponent,
    HighlightSearchPipe,
    AddRunnerComponent,
    LoaderComponent,
    SafePipe,
    TeamTypeComponent,
    AddRunnerComponent,
    AddCatcherComponent,
    MyTeamsComponent,
    RunningTeamComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    AngularFireAuthGuardModule,
    FormsModule,
    NgbModule,
    HttpClientModule,
  ],
  providers: [
    AdminGuard,
    DecimalPipe,
    // {provide: NgbDateAdapter, useClass: NgbDateNativeAdapter},
    {provide: NgbDateAdapter, useClass: NgbDateFirestoreAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter},
    [PendingChangesGuard]
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
