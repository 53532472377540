<header>
  <nav class="navbar navbar-expand-lg navbar-light bg-white">
    <div class="container-fluid mx-2">
      <a class="navbar-brand text-primary fw-900 me-0" href="#">
        <span class="widegame-logo">widegame.app</span><span class="d-none d-sm-inline"> My Teams</span>
      </a>
      <div  class="d-flex">

<!--        <form>-->
<!--          <div class="input-group input-group-sm">-->
<!--            <span class="input-group-text border-light">Game:</span>-->
<!--            <select class="form-select form-select-sm border-light bg-light" name="gameSelect">-->
<!--              <option>{{gameService.currentGame.name}}</option>-->
<!--            </select>-->

<!--          </div>-->

<!--        </form>-->

<!--        <button type="button" class="btn btn-light btn-sm ms-2">Catcher's App<i class="fas fa-external-link-alt ms-2"></i></button>-->
<!--        <button type="button" class="btn btn-light btn-sm ms-2">Teams Manager<i class="fas fa-external-link-alt ms-2"></i></button>-->
<!--        <button type="button" class="btn btn-light btn-sm ms-2">Results <i class="fas fa-external-link-alt ms-2"></i></button>-->

        <div class="btn-group">
          <button class="btn btn-primary btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="fas fa-user-alt me-2"></i> <span class="d-none d-sm-inline">{{ auth.user.displayName }}</span>
          </button>
          <ul class="dropdown-menu dropdown-menu-end">
<!--            <li><a class="dropdown-item" href="#"><i class="fas fa-user-edit me-2"></i>Account</a></li>-->
            <li><button class="dropdown-item" (click)="auth.logout()"><i class="fas fa-sign-out-alt me-2"></i>Logout</button></li>
          </ul>
        </div>
      </div>
    </div>
  </nav>

<!--  <div class="bg-light">-->
<!--    <ul class="nav justify-content-center main-nav">-->
<!--      <a class="nav-link" routerLink="/" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Dashboard</a>-->
<!--      <a class="nav-link" routerLink="/runners" routerLinkActive="active">Running Teams</a>-->
<!--      <a class="nav-link" routerLink="/catchers" routerLinkActive="active">Catching Teams</a>-->
<!--      <a class="nav-link" routerLink="/registration" routerLinkActive="active">Registration</a>-->
<!--      <a class="nav-link" routerLink="/live-catches" routerLinkActive="active">Live Catches</a>-->
<!--      <a class="nav-link" routerLink="/check-in" routerLinkActive="active">Check-in</a>-->
<!--      <a class="nav-link" routerLink="/leaderboard" routerLinkActive="active">Leaderboard</a>-->
<!--      <a class="nav-link" routerLink="/settings" routerLinkActive="active">Settings</a>-->
<!--    </ul>-->
<!--  </div>-->

</header>
