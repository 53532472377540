import { Component, OnInit } from '@angular/core';
import {Catcher} from "../../../../models/catcher";
import {CatcherService} from "../../../../services/catcher.service";
import {Router} from "@angular/router";

@Component({
  selector: 'app-catcher',
  templateUrl: './add-catcher.component.html',
  styles: [
  ]
})
export class AddCatcherComponent implements OnInit {

  catcher = {} as Catcher;

  constructor(private catcherService: CatcherService, private router: Router) { }

  ngOnInit(): void {
  }

  create(): void {
    if (this.validate()) {
      this.catcherService.create(this.catcher);
      this.router.navigate(['/']);
    }
  }

  validate(): boolean {
    return this.catcher.name?.length > 3 && this.catcher.contactNumber?.length > 9;
  }

}
