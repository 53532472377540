import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {Observable} from 'rxjs';
import {Game} from '../models/game';
import {mergeMap} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class GameService {

  games$: Observable<Game[]>;
  currentGame$: Observable<Game>; // Just for this session, set by default as currentGame from firestore settings document
  games: Game[];
  currentGame: Game; // Just for this session, set by default as currentGame from firestore settings document

  constructor(public afs: AngularFirestore) {
    // @ts-ignore
    this.getCurrentGame();
    this.games$ = this.afs.collection<Game>('games').valueChanges();
    this.games$.subscribe(games => this.games = games);
    this.currentGame$.subscribe(game => this.currentGame = game);
  }

  getCurrentGame(): Observable<Game> {
    return this.currentGame$ = this.afs.doc<Game>('settings/currentGame').valueChanges().pipe(mergeMap(current => {
      return this.afs.doc<Game>(`games/${current.id}`).valueChanges();
    }));
  }

  // SubmissionsOpen() is in Auth service to avoid circular DI - can get username from there instead
}

