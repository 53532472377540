<!-- This should show a list of teams if there are any, if not show the team-type component -->
<div class="container mt-5">

  <h1>Hello, {{auth.user.displayName?.split(' ')[0]}}!</h1>
  <p class="text-gray">Here you can manage all your running and catching teams for {{game.currentGame.name}}.</p>

  <div *ngFor="let message of game.currentGame.teamsMessages" class="alert {{message.bg ? 'alert-' + message.bg : 'alert-primary'}} mb-2" role="alert">
    <span *ngIf="message.bg == null || message.bg == undefined"><i class="fa-solid fa-circle-info me-2"></i><b>Message from the organisers: </b></span>
    <span *ngIf="message.bg === 'info'"><i class="fa-solid fa-circle-info me-2"></i><b>Message from the organisers: </b></span>
    <span *ngIf="message.bg === 'success'"><i class="fa-solid fa-circle-info me-2"></i><b>Message from the organisers: </b></span>
    <span *ngIf="message.bg === 'warning'"><i class="fa-solid fa-triangle-exclamation me-2"></i><b>Message from the organisers: </b></span>
    <span *ngIf="message.bg === 'danger'"><i class="fa-solid fa-triangle-exclamation me-2"></i><b>Message from the organisers: </b></span>
    {{message.message}}
  </div>


  <!--  If has teams: //TODO: Map catchers and runners so it's in one subscription -->
  <div class="mt-3" *ngIf="(runnerService.teams$ | async) as runningTeams else loading">


    <!-- Banners for submissions open & team validation  -->
    <div *ngIf="auth.submissionsOpen(); else submissionsClosed">

       <div *ngIf="!runnerService.allRunnersValid(runningTeams)" class="alert alert-warning mb-2" role="alert">
        <i class="fa-solid fa-triangle-exclamation me-2"></i><b>Warning: </b>
        At least one of your teams is incomplete. Please make sure all teams have the relevant info by <b>{{game.currentGame.submissionDeadline.toMillis() | date: 'EEEE, dd MMMM y'}}</b>. After this point changes will not be allowed.
      </div>

      <div *ngIf="runnerService.allRunnersValid(runningTeams) && runningTeams.length > 0" class="alert alert-success mb-2" role="alert">
        <i class="fa-solid fa-triangle-exclamation me-2"></i><b>Great! </b>All your teams look good! You can continue editing them until <b>{{game.currentGame.submissionDeadline.toMillis() | date: 'EEEE, dd MMMM y'}}</b>. After this point changes will not be allowed.
      </div>

      <div *ngIf="runningTeams.length == 0" class="alert alert-warning mb-2" role="alert">
        <i class="fa-solid fa-triangle-exclamation me-2"></i><b>Warning: </b>
        The submission deadline is <b>{{game.currentGame.submissionDeadline.toMillis() | date: 'EEEE, dd MMMM y'}}</b>. After this point team submissions & edits will not be allowed.
      </div>

    </div>

    <ng-template #submissionsClosed>
      <div class="alert alert-danger mb-2" role="alert">
        <i class="fa-solid fa-triangle-exclamation me-2"></i><b>Warning: </b>
          Running team submissions have now closed and you cannot make any changes. If this is an issue, please contact the organising team.
      </div>
    </ng-template>

    <div *ngIf="(runningTeams?.length == 0 && (catcherService.catcher$ | async) == null) && auth.submissionsOpen();">

      <p class="text-primary mb-3 mt-5"><i class="fa-solid fa-circle-info me-2"></i>You don't need to know everything about your teams yet, just get started & change things later!</p>

      <app-team-type></app-team-type>

    </div>

    <div *ngIf="runningTeams.length > 0 || (catcherService.catcher$ | async) != null">
      <div class="d-flex justify-content-between mt-4">
        <h3 class="mb-4">Running Teams</h3>
        <div><button class="btn btn-primary" [disabled]="!auth.submissionsOpen()" routerLink="/add-team/runner"><i class="fa-solid fa-plus me-2"></i>New
          Running Team</button></div>
      </div>
      <p class="mt-0 mb-4">Running teams must consists of 4-7 members including leaders. Don't forget to add any dietary requirements!</p>
      <p *ngIf="runningTeams.length == 0" class="text-primary mt-2 mb-5"><i class="fa-solid fa-circle-info me-2"></i>You haven't added any running teams yet, click the button above to get started.</p>
      <div *ngFor="let team of runningTeams">
        <div [routerLink]="auth.submissionsOpen() ? '/runner/' + team.id : null" class="shadow mb-4 mt-2 border border-light border-1" [class.cursor-pointer]="auth.submissionsOpen()">
          <div class="p-3">
            <div class="d-flex">
              <h4 class="flex-grow-1">{{team.name}}</h4>
              <div class="ms-1">
                <button *ngIf="auth.submissionsOpen()" class="btn btn-outline-primary"><i class="fa-solid fa-pen me-2"></i>Edit</button>
              </div>
            </div>
            <h6 class="card-subtitle mb-1 text-muted mt-1 mb-mb-0">
              <span class="mt-1 me-2 badge bg-light text-dark">Team {{team.teamNumber | number:'3.0'}}</span>
              <span class="mt-1 me-2 badge bg-primary">{{team.category}}</span>
              <span class="mt-1 me-2 badge bg-secondary"><span class="fw-normal">Group:</span> {{team.group}}</span>
            </h6>
            <p *ngIf="runnerService.checkRunner(team) as errors" class="text-danger mb-0 mt-2 small">
              <span *ngIf="errors.length > 1"><i class="fa-solid fa-triangle-exclamation me-2"></i>This team is incomplete! ({{errors.length}} issues).</span>
              <span *ngIf="errors.length === 1"><i class="fa-solid fa-triangle-exclamation me-2"></i>{{errors[0]}}</span>
            </p>
          </div>
          <div class="bg-light p-3 d-flex flex-nowrap scrolling-wrapper w-100">
            <div class="text-primary small" *ngIf="team.members?.length == 0"><i class="fa-solid fa-circle-info me-2"></i>You haven't added any members just yet!</div>
            <div *ngFor="let member of team.members; index as i" class="bg-white card-member text-center p-2 me-3">
              <h2><i class="fa-solid fa-person-hiking text-{{i == 0 ? 'primary' : 'secondary'}} mt-2"></i></h2>
              <p class="fw-900 mb-0">{{member.name?.length > 0 ? member.name : 'Name not set'}}</p>
              <p *ngIf="i == 0" class="text-gray small my-1">Team Leader</p>
            </div>
          </div>
          <div class="p-3">
            <div class="row">
              <div class="col-8">
                <b>Cost for this team</b> ({{team.members.length}}x members, {{game.currentGame.costPerPerson | currency:'GBP'}}pp):
              </div>
              <div class="col-4">
                <p class="mb-0 text-end ms-2 fw-bold">{{(team.members.length * game.currentGame.costPerPerson) | currency:'GBP' }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="shadow mb-4 mt-2 p-3">
        <div class="row">
          <div class="col-md-8">
            <h5>Total cost</h5>
            <p>Total cost for all teams - funds raised this year will go to the Astley Hall School.</p>
            <p>Please bring this on the day in cash or cheque payable to Eccleston Scout Group, or by bank transfer using the details below:</p>
            <p class="mb-1">Eccleston Scout Group</p>
            <p class="mb-1">Sort code: <b>01-01-94</b></p>
            <p class="mb-0">Account No.: <b>20619642</b></p>
          </div>
          <div class="col-md-4 text-end p-3">
            <h4 class="mt-4">{{getTotalCost(runningTeams) | currency:'GBP'}}</h4>
          </div>
        </div>
      </div>
    </div>

    <div>&nbsp;</div>

    <div *ngIf="(catcherService.catcher$ | async) as catcher">
      <h3 class="mt-4">Your Catching Team</h3>
      <p>Keep your login details for this app safe - you'll need them on the day to sign into the catcher's app.</p>
      <div class="shadow mb-4 mt-4 border border-light border-1">
        <div class="p-3">
          <h4 class="flex-grow-1">{{catcher.name}}</h4>
          <p class="text-gray mb-1">Team Leader: <strong>{{catcher.contactName}}</strong></p>
          <p class="text-gray mb-1">Contact Number: <strong>{{catcher.contactNumber}}</strong></p>
          <p class="text-gray mb-1">Contact Email: <strong>{{catcher.contactEmail}}</strong></p>
          <p *ngIf="catcher.otherMembers != undefined && catcher.otherMembers.length > 0" class="text-gray mb-2">Other Members: <strong>{{catcher.otherMembers}}</strong></p>
          <p class="text-primary mb-0"><i class="fa-solid fa-circle-info me-2"></i>To make changes to your catching team, please contact the organising team.</p>
        </div>
      </div>
    </div>

    <div *ngIf="(catcherService.catcher$ | async) == null && runningTeams.length > 0">
      <h3 class="mb-3 mt-5">Your Catching Team</h3>
      <p>Only make one catching team for yourself. Each catcher must sign themselves up to the app.</p>
      <p class="text-primary mb-4"><i class="fa-solid fa-circle-info me-2"></i>You haven't created your catching team yet. To get started, click here:</p>
      <div><a class="btn btn-primary" routerLink="/add-team/catcher"><i class="fa-solid fa-plus me-2"></i>Create
        Catching Team</a></div>
    </div>

    <div>&nbsp;</div>

    <h3 class="mt-4">Other Rules & Info</h3>
    <p>Here's some other handy info for the event!</p>
    <ul>
      <li><b>Runners: </b>The game will start at 12:00 (11:30 meet) at a yet to be disclosed location.</li>
      <li><b>Catchers: </b>The game will start at 13:00 (12:30 meet) at Eccleston Scout Hut, Drapers Ave, PR7 5SY.</li>
      <li>There is often not a huge amount of parking and the start & finish so please lift share as much as possible.</li>
      <li>Running teams must be between 4-7 members including leaders.</li>
      <li>The finish window for runners will be between 17:45 and 18:00 at Eccleston Scout Hut (GR SD 523 169). There may not be a firework so please keep an eye on your watch.</li>
      <li>On the day, make sure you have:</li>
      <ul>
        <li>Money: £5 per running team member, unless paid by bank transfer</li>
        <li>Explorer 285 (preferred) or Landranger 108 map</li>
        <li>Appropriate clothing (hat, gloves, waterproof, boots etc.)</li>
        <li>Rucksack, water bottle, head torch, small first aid kit</li>
        <li>Mobile phone – fully charged (download what3words here for emergencies) <b>This must be on from 17:45 onwards for emergency contact!</b></li>
      </ul>
      <li>Any questions, please email <a href="mailto:george@ecclestonscouts.uk" class="text-primary">george@ecclestonscouts.uk</a>.</li>
    </ul>

  </div>

  <ng-template #loading>
    <app-loader></app-loader>
  </ng-template>
</div>

<!--<p>-->
<!--  For each team, show all info (non editable - edit page needs to be turned off after certain date)-->
<!--  Allow editing (team name, contact info, etc.)-->
<!--  Show all members & their info (again edit button which will be turned off)-->
<!--  Allow moving members to other teams-->
<!--  Show pricing breakdown (and total for all teams this leader has)-->

<!--</p>-->

